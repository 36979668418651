.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 50vh;
}

.loader::before {
  animation: spin 2s linear infinite;
  border: 12px solid var(--color-surface);
  border-radius: 50%;
  border-top: 12px solid var(--color-brand);
  content: '';
  height: 120px;
  width: 120px;
}

.loaderWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay);
  z-index: 1;
  display: flex;
}

.loaderWrapper .loaderHeight {
  min-height: unset;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
