:root {
  --color-brand-orange: #ff8900;
  --color-brand-orange-transparency: rgba(255, 137, 0, 0.7);
  --color-brand-blue1: #192B4B;
  --color-brand-blue2: #133255;
  --color-brand-blue3: #427CC1;
  --color-brand-blue3-transparency20: rgba(66, 124, 193, 0.2);
  --color-brand-blue3-transparency40: rgba(66, 124, 193, 0.4);
  --color-brand-blue3-transparency70: rgba(66, 124, 193, 0.7);
  --color-brand-blue4: #008FFB;
  --color-brand-blue5: #1BBFFC;
  --color-brand-blue6: #48B4FA;
  --color-brand-blue7: #86A8D1;
  /* --color-brand-footer: #e5973c; */
  --color-brand-footer-auth: #f5f8fa;
  --color-brand-footer-auth-dark: #171717;
  --color-brand-cotton: #fff;
  --color-brand-cotton-transparency: rgba(255, 255, 255, 0.6);
  --color-brand-tool: #3e3e3e;
  /* --color-brand-tool: #606060; */
  --color-secondary-sky: #c7d8ed;
  /* --color-secondary-melon: #fcd8ae; */
  /* --color-secondary-cider: #b16003; */
  /* --color-secondary-pumpkin: #e5973c; */
  --color-secondary-ocean: #283442;
  /* --color-secondary-honey: #50310D; */
  --color-terciary-butter: #ffe781;
  /* --color-terciary-canary: #fac000; */
  --color-neutral-pearl: #f4f8fa;
  --color-neutral-dolphin: #d3d3d3;
  --color-neutral-shadow: #606060;
  --color-neutral-raven: #222222;
  --color-feedback-ferrari: #c60606;
  /* --color-feedback-reedwood: #985555; */
  --color-feedback-jungle: #05a896;
  /* --color-feedback-tiffany: #76c4bb; */

  /* --color-brand-titles: #3c3b3b; */
  --color-light-medium-grey: #94969e;
  --color-dark: #000000;
  --color-medium-dark: #2C2B2B;
  --color-midnight-grey: #2B2A2A;
  --color-medium-light-dark: #3E3D3D;
  --color-pwa-dark: #313030;
  --color-midnight: #121212;

  --height-sidebar-mobile: 5rem;
  --width-sidebar-desktop: 4rem;
  --width-sidebar-desktop-open: 16rem;
  --radius-btn: 4px;
  --radius-input: 4px;
  --radius-stats: 4px;
  --witdth-media-1024: 1024px;
  --witdth-media-860: 860px;
  --witdth-media-620: 620px;
  --witdth-media-mobile: 425px;

  /* Normal mode */
  --color-background: var(--color-brand-cotton);
  --color-background-secondary: var(--color-neutral-pearl);
  --color-background-terciary: var(--color-neutral-pearl);
  --color-background-shape: var(--color-neutral-pearl);
  --color-surface: var(--color-neutral-dolphin);
  --color-onBackground: var(--color-neutral-raven);
  --color-onBackground-secondary: var(--color-brand-tool);
  --color-onBackground-terciary: var(--color-brand-tool);
  --color-onSurface: var(--color-neutral-shadow);

  --color-brand: var(--color-brand-blue3);
  --color-overlay: rgba(255, 255, 255, 0.8);
  --color-overlay-negative: rgba(0, 0, 0, 0.1);
  --color-disabled-text: rgba(0, 0, 0, 0.8);

  --color-sidebar: var(--color-brand-blue1);
  --color-sidebar-link-active: var(--color-brand-blue3-transparency40);
  --color-sidebar-link-hover: var(--color-brand-blue3-transparency70);
  --color-sidebar-icons: var(--color-brand-cotton-transparency);
  --color-sidebar-icons-active: var(--color-brand-cotton);
  --color-sidebar-icons-hover: var(--color-brand-cotton);

  --color-home-titles: var(--color-brand-blue2);
  --color-titles: var(--color-brand-blue1);
  --color-titles-lvl2: var(--color-brand-blue4);
  --color-footer: var(--color-brand-blue2);
  --color-footer-auth: var(--color-brand-footer-auth);
  --color-home-card: var(--color-brand-cotton);
  --color-statsBar: var(--color-brand-cotton);
  --color-importedBookmakers: var(--color-brand-cotton);
  --color-pwaBtn: var(--color-brand-cotton);
  --color-pwa-background: var(--color-neutral-pearl);

  --color-btn-text: var(--color-brand-blue5);
  --color-btn-text-light: var(--color-brand-blue5);
  --color-btn-text-hover: var(--color-brand-blue3);
  --color-btn-text-active: var(--color-brand-blue3);
  --color-btn-text-disabled: var(--color-brand-cotton);
  --color-btn: var(--color-brand-blue2);
  --color-btn-hover: var(--color-brand-blue2);
  --color-btn-selected: var(--color-brand-blue2);
  --color-btn-disabled: var(--color-neutral-dolphin);

  --color-btn-secondary-text: var(--color-brand-blue5);
  --color-btn-secondary-text-hover: var(--color-brand-blue6);
  --color-btn-secondary-text-active: var(--color-brand-blue3);
  --color-btn-secondary-hover: var(--color-neutral-pearl);
  --color-btn-secondary-background: var(--color-neutral-pearl);

  --color-icon: var(--color-brand-blue1);
  --color-icon-light: var(--color-brand-blue3);
  --color-icon-lighter: var(--color-brand-blue5);
  --color-row: var(--color-neutral-pearl);
  --color-row-active: var(--color-secondary-sky);
  --color-autocomplete-disabled: var(--color-brand-cotton);
  --color-accountBtn: var(--color-neutral-pearl);
}

:root[data-theme='theme-dark'] {
  --color-background: var(--color-neutral-raven);
  --color-background-secondary: var(--color-brand-tool);
  --color-background-terciary: var(--color-dark);
  --color-background-shape: var(--color-medium-dark);
  --color-surface: var(--color-neutral-shadow);
  --color-onBackground: var(--color-brand-cotton);
  --color-onBackground-secondary: var(--color-neutral-pearl);
  --color-onBackground-terciary: var(--color-brand-cotton);
  --color-onSurface: var(--color-neutral-dolphin);

  --color-overlay: rgba(0, 0, 0, 0.6);
  --color-overlay-negative: rgba(83, 83, 83, 0.081);
  --color-disabled-text: rgba(255, 255, 255, 0.8);
  --color-sidebar: var(--color-pwa-dark);
  --color-home-titles: var(--color-brand-blue7);
  --color-footer-auth: var(--color-brand-tool);
  --color-home-card: var(--color-midnight-grey);
  --color-statsBar: var(--color-midnight-grey);
  --color-importedBookmakers: var(--color-brand-titles);
  --color-pwaBtn: var(--color-medium-light-dark);
  --color-pwa-background: var(--color-midnight-grey);

  --color-btn-text: var(--color-brand-blue1);
  --color-btn-text-hover: var(--color-brand-blue1);
  --color-btn-text-active: var(--color-brand-blue1);
  --color-btn-text-disabled: var(--color-brand-blue1);
  --color-btn: var(--color-brand-blue5);
  --color-btn-hover: var(--color-brand-blue6);
  --color-btn-selected: var(--color-brand-blue3);

  --color-btn-secondary-hover: var(--color-brand-blue3);
  --color-btn-secondary-background: var(--color-brand-tool);

  --color-icon: var(--color-neutral-pearl);
  --color-row: var(--color-midnight);
  --color-row-active: var(--color-secondary-ocean);
  --color-autocomplete-disabled: var(--color-brand-tool);
  --color-accountBtn: var(--color-brand-tool);
}